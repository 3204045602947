/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:ba72695f-7bc0-4835-84dc-c713faacdff8",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_OUvDcPgXW",
    "aws_user_pools_web_client_id": "3r0crlrcc067ncc4pt6llks7k2",
    "oauth": {
        "domain": "piggybankofhappinesscf2e2c90-cf2e2c90-prd.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "exp://127.0.0.1:19000/--/,exp://exp.host/@community/with-webbrowser-redirect/,exp://192.168.1.101:19000/--/,piggybankofhappiness://",
        "redirectSignOut": "exp://127.0.0.1:19000/--/,exp://exp.host/@community/with-webbrowser-redirect/,exp://192.168.1.101:19000/--/,piggybankofhappiness://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_appsync_graphqlEndpoint": "https://ye2nmbhmqzdvfk3rggvmhdhl7i.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://sx5x9ujgga.execute-api.ap-southeast-1.amazonaws.com/prd",
            "region": "ap-southeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "piggybankofhappiness5006fc9e76144bceade20e5d953170807-prd",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
