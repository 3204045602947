export default [
  {
    label: '審核中',
    value: 'Pending',
  },
  {
    label: '已通過',
    value: 'Approved',
  },
  {
    label: '已拒絕',
    value: 'Rejected',
  },
];
