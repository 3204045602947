export default [
  {
    label: 'APP管理者',
    value: 'AppAdmins',
  },
  {
    label: '機構管理者',
    value: 'OrgAdmins',
  },
  {
    label: '機構職員',
    value: 'OrgManagers',
  },
  {
    label: '用戶',
    value: 'Users',
  },
];
