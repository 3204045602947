export default [
  {
    label: '管理員',
    value: 'Admin',
  },
  {
    label: '職員',
    value: 'Manager',
  },
  {
    label: '學生',
    value: 'User',
  },
  {
    label: '審核中',
    value: 'PendingApproval',
  },
];
