export default [
  {
    label: '存款',
    value: 'credits',
  },
  {
    label: '提取',
    value: 'withdraw',
  },
  {
    label: '調整',
    value: 'adjustment',
  },
  {
    label: '取消',
    value: 'cancel',
  },
  {
    label: '合併',
    value: 'combine',
  },
  {
    label: '兌換',
    value: 'reward',
  },
];
