import Colors from 'constants/Colors';

export default [
  {
    label: '使用中',
    value: 1,
    color: Colors.dark,
  },
  {
    label: '已停用',
    value: 0,
    color: Colors.light,
  },
];
